export default [{
  id: 'numQuestions',
  name: 'Questions',
  type: Number,
  input: 'input',
  inputType: 'number',
  "default": 10,
  min: 1,
  max: 50
}, {
  id: 'difficulty',
  name: 'Difficulty',
  type: String,
  input: 'select',
  "default": 'any',
  options: [{
    key: 'any',
    value: 'Any'
  }, {
    key: 'easy',
    value: 'Easy'
  }, {
    key: 'medium',
    value: 'Medium'
  }, {
    key: 'hard',
    value: 'Hard'
  }]
}, {
  id: 'type',
  name: 'Type',
  type: String,
  input: 'select',
  "default": 'any',
  options: [{
    key: 'any',
    value: 'Any'
  }, {
    key: 'multiple',
    value: 'Multiple Choice'
  }, {
    key: 'boolean',
    value: 'True/False'
  }]
}, {
  id: 'categories',
  name: 'Categories',
  type: [Number],
  input: 'multiple-select',
  "default": [9, 10, 11, 12],
  options: [{
    key: 9,
    value: 'General Knowledge'
  }, {
    key: 10,
    value: 'Entertainment: Books'
  }, {
    key: 11,
    value: 'Entertainment: Film'
  }, {
    key: 12,
    value: 'Entertainment: Music'
  }, {
    key: 13,
    value: 'Entertainment: Musicals & Theatres'
  }, {
    key: 14,
    value: 'Entertainment: Television'
  }, {
    key: 15,
    value: 'Entertainment: Video Games'
  }, {
    key: 16,
    value: 'Entertainment: Board Games'
  }, {
    key: 17,
    value: 'Science & Nature'
  }, {
    key: 18,
    value: 'Science: Computers'
  }, {
    key: 19,
    value: 'Science: Mathematics'
  }, {
    key: 20,
    value: 'Mythology'
  }, {
    key: 21,
    value: 'Sports'
  }, {
    key: 22,
    value: 'Geography'
  }, {
    key: 23,
    value: 'History'
  }, {
    key: 24,
    value: 'Politics'
  }, {
    key: 25,
    value: 'Art'
  }, {
    key: 26,
    value: 'Celebrities'
  }, {
    key: 27,
    value: 'Animals'
  }, {
    key: 28,
    value: 'Vehicles'
  }, {
    key: 29,
    value: 'Entertainment: Comics'
  }, {
    key: 30,
    value: 'Science: Gadgets'
  }, {
    key: 31,
    value: 'Entertainment: Japanese Anime & Manga'
  }, {
    key: 32,
    value: 'Entertainment: Cartoon & Animations'
  }]
}];