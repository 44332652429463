import colors from '#src/colors';
import FlagRedIcon from '#public/icons/flag-red.svg';
import FlagBlueIcon from '#public/icons/flag-blue.svg';
export var teamColors = [{
  color: colors.red,
  light: colors.lightRed,
  icon: FlagRedIcon
}, {
  color: colors.secondary,
  light: colors.secondaryLight,
  icon: FlagBlueIcon
}];