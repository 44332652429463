const colors = {
  text: {
    primary: '#102B4C',
  },
  navy: '#102B4C',
  primary: '#ffd568',
  primaryDarkened: '#bc8800',
  primaryLightened: '#ffeec3',
  secondary: '#54eff1',
  secondaryLight: '#cffbfc',
  backgroundColor: '#fff',
  thirdColor: '#e48ca5',
  green: '#79F39B',
  darkGreen: '#008000',
  red: '#ff0000',
  midGrey: '#c8c8c8',
  darkGrey: '#5c5c5c',
  lightGrey: '#eaeaea',
  lighestGrey: '#f9f9f9',
  background: '#fff',
  backgroundContrast: '#000',
  canvas: '#eaeaea',
  white: '#fff',
  black: '#000',
  transparent: '#fff0',
  darkNavy: '#102B4C',
  blue: 'blue',
  avatarBackground: '#c3c3c3',
  lightGreen: '#c9ffc0',
  lightRed: '#ffc1c1',
  darkRed: '#8B0000',
  avatarColors: {
    black: '#000',
    auburn: '#922724',
    blonde: '#F0E2B6',
    blondegolden: '#fbe7a1',
    brown: '#964B00',
    browndark: '#654321',
    pastelpink: '#dea5a4',
    blue: 'blue',
    platinum: '#e5e4e2',
    red: 'red',
    silvergray: '#C0C0C0',
    blue01: '#45cafb',
    blue02: '#399ae0',
    blue03: '#13567a',
    gray01: '#e6e6e6',
    gray02: '#919598',
    heather: '#384f5b',
    pastelblue: '#a8e2fd',
    pastelgreen: '#96fcc6',
    pastelorange: '#ffdeb9',
    pastelred: '#ffb0b9',
    pastelyellow: '#fffeb8',
    pink: '#ff4d8c',
  },
};

export default colors;
