import socket from './socket';
import { store } from './store';
import { receiveGameStep, clearGameStep } from './actions/gameStep.actions';
import { receiveLobby } from './actions/lobby.actions';
import Emitter, { GAME_STEP_UPDATED, GAME_EVENT } from './emitter';
var listeners = [{
  name: 'step',
  cb: function cb(payload) {
    return store.dispatch(receiveGameStep(payload));
  }
}, {
  name: 'step-update',
  cb: function cb(payload) {
    return Emitter.emit(GAME_STEP_UPDATED, payload);
  }
}, {
  name: 'lobby',
  cb: function cb(payload) {
    return store.dispatch(receiveLobby(payload));
  }
}, {
  name: 'game-event',
  cb: function cb(payload) {
    return Emitter.emit(GAME_EVENT, payload);
  }
}];
export default function connectSocket(hash, navigate) {
  var forceRefresh = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  socket(hash, navigate, listeners, forceRefresh, function () {
    return store.dispatch(clearGameStep());
  });
}