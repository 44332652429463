export var YES_OR_NO = 'YES_OR_NO';
export var NUMERICAL = 'NUMERICAL';
export var CHOOSE_PERSON = 'CHOOSE_PERSON';
export default [{
  question: 'Do you enjoy outdoor activities like hiking or biking?',
  type: YES_OR_NO
}, {
  question: 'Have you ever participated in a charity run or walk event?',
  type: YES_OR_NO
}, {
  question: 'Do you enjoy attending local cultural festivals?',
  type: YES_OR_NO
}, {
  question: 'Have you ever taken a spontaneous weekend getaway?',
  type: YES_OR_NO
}, {
  question: 'Have you seen The Godfather?',
  type: YES_OR_NO
}, {
  question: 'Have you seen Game of Thrones?',
  type: YES_OR_NO
}, {
  question: 'Did you have a job in highschool?',
  type: YES_OR_NO
}, {
  question: 'Did you ever get detention in school?',
  type: YES_OR_NO
}, {
  question: 'Do you like horror films?',
  type: YES_OR_NO
}, {
  question: 'Have you ever been pulled over for speeding?',
  type: YES_OR_NO
}, {
  question: 'Do you have an android phone?',
  type: YES_OR_NO
}, {
  question: 'Are you a fast typer?',
  type: YES_OR_NO
}, {
  question: 'Do you like sitcoms?',
  type: YES_OR_NO
}, {
  question: 'Do you drink coffee every morning?',
  type: YES_OR_NO
}, {
  question: 'Do you own a sports jersey?',
  type: YES_OR_NO
}, {
  question: 'Do you have a pet?',
  type: YES_OR_NO
}, {
  question: 'Do you know how to play chess?',
  type: YES_OR_NO
}, {
  question: 'Do you know how to play an instrument?',
  type: YES_OR_NO
}, {
  question: 'How many pairs of shoes do you own?',
  type: NUMERICAL,
  from: 1,
  to: 10,
  plus: true
}, {
  question: 'On a scale of 1-10, how interested would you be in attending a magic show?',
  type: NUMERICAL,
  from: 1,
  to: 10
}, {
  question: 'On a scale of 1-10, how interested would you be watching a chess tournament?',
  type: NUMERICAL,
  from: 1,
  to: 10
}, {
  question: 'On a scale of 1-10, how interested would you in going on a cruise?',
  type: NUMERICAL,
  from: 1,
  to: 10
}, {
  question: 'How many live music concerts have you been to in the last year?',
  type: NUMERICAL,
  from: 0,
  to: 5,
  plus: true
}, {
  question: 'How many times have you been to the movies in the last year?',
  type: NUMERICAL,
  from: 0,
  to: 5,
  plus: true
}, {
  question: 'How many sports do you follow?',
  type: NUMERICAL,
  from: 0,
  to: 5,
  plus: true
}, {
  question: 'How many movies have you watched in the past month?',
  type: NUMERICAL,
  from: 0,
  to: 5,
  plus: true
}, {
  question: 'How many books have you read in the past month?',
  type: NUMERICAL,
  from: 0,
  to: 5,
  plus: true
}, {
  question: 'How many phones have you had in the past 10 years?',
  type: NUMERICAL,
  from: 0,
  to: 5,
  plus: true
}, {
  question: 'How many cups of coffee/tea do you drink each day?',
  type: NUMERICAL,
  from: 0,
  to: 5,
  plus: true
}, {
  question: 'How many social medias do you actively use?',
  type: NUMERICAL,
  from: 0,
  to: 7,
  plus: true
}, {
  question: 'How many minutes does it take you to get ready in the morning?',
  type: NUMERICAL,
  from: 10,
  to: 60,
  plus: true
}, {
  question: 'How many different countries have you traveled to?',
  type: NUMERICAL,
  from: 1,
  to: 10,
  plus: true
}, {
  question: 'On a scale of 1 to 10, how much do you enjoy trying out new recipes at home?',
  type: NUMERICAL,
  from: 1,
  to: 10
}, {
  question: 'How many books do you typically read in a year?',
  type: NUMERICAL,
  from: 1,
  to: 10,
  plus: true
}, {
  question: 'On a scale of 1 to 10, how much do you enjoy attending live performances?',
  type: NUMERICAL,
  from: 1,
  to: 10
}, {
  question: 'Who is most likely to go sky diving?',
  type: CHOOSE_PERSON
}, {
  question: 'Who do you think knows the most about coffee?',
  type: CHOOSE_PERSON
}, {
  question: 'Who do you think knows the most about wine?',
  type: CHOOSE_PERSON
}, {
  question: 'Who do you know the least about?',
  type: CHOOSE_PERSON
}, {
  question: 'Who do you know the most about?',
  type: CHOOSE_PERSON
}, {
  question: 'Who do you think knows the most about video games?',
  type: CHOOSE_PERSON
}, {
  question: 'Who do you think is the best at baking?',
  type: CHOOSE_PERSON
}, {
  question: 'Who do you think is the best at cooking?',
  type: CHOOSE_PERSON
}, {
  question: 'Who do you think has the most unread emails?',
  type: CHOOSE_PERSON
}, {
  question: 'Who is most likely to be late to a meeting?',
  type: CHOOSE_PERSON
}, {
  question: 'Who do you think listens to the most different types of music?',
  type: CHOOSE_PERSON
}, {
  question: 'Who would you be most likely to designate as DJ at a party?',
  type: CHOOSE_PERSON
}, {
  question: 'Who do you think has the best design sense?',
  type: CHOOSE_PERSON
}];