import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types'; // Import PropTypes

import { NavLink } from 'react-router-dom';

import colors from '#src/colors';
import theme from '#src/theme';

const baseButtonStyle = css`
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 100ms ease;
  font-weight: 500;
  font-size: 16px;
  outline: 0;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 12px;
  filter: brightness(100%);
  user-select: none;
  border: 0;
`;

const primaryButtonStyle = css<ButtonProps>`
  ${baseButtonStyle}
  background-color: ${props => props.backgroundColor || colors.primary};
  color: ${props => props.color || colors.text.primary};
  border: ${props => props.noBorder ? '0px' : `${theme.borderThickness}px solid ${colors.text.primary}`};
  box-shadow: ${props => props.noShadow ? '' : `3px 3px 0px -${theme.borderThickness}px ${colors.text.primary}, 3px 3px 0 0px ${colors.text.primary}`};
  margin-bottom: ${props => props.noBorder ? '0px' : '3px'};
  transform: translate(0px, 0px);
  &:hover {
    filter: brightness(85%);
  }
  &:active {
    filter: brightness(85%);
    transform: ${props => props.noShadow ? '' : 'translate(3px, 3px)'};
    box-shadow: ${props => props.noShadow ? '' : `0px 0px 0px -3px ${colors.text.primary}, 0px 0px 0 0px ${colors.text.primary}`};
  }
  &:disabled {
    cursor: default;
    transform: ${props => props.noShadow ? '' : 'translate(0px, 0px)'};
    box-shadow: ${props => props.noShadow ? '' : `3px 3px -${theme.borderThickness}px ${colors.text.primary}, 3px 3px 0 0px ${colors.text.primary}`};
    background-color: ${props => props.backgroundColor ? props.backgroundColor : colors.lightGrey};
    filter: ${props => props.backgroundColor ? 'brightness(100%)' : 'none'};
  }
};`

const PrimaryStyledButton = styled.button`
  ${primaryButtonStyle}
`

const SecondaryStyledButton = styled.button`
  ${baseButtonStyle}
  background-color: ${colors.text.primary};
  color: ${colors.white};
  padding: 12px 15px;
};`

const HrefStyledButton = styled.a.attrs(props => ({}))<any>`
  ${primaryButtonStyle}
`

const ToStyledButton = styled(NavLink as React.ComponentType<any>)`
  ${primaryButtonStyle}
`

interface ButtonProps {
  children?: React.ReactNode;
  className?: string;
  href?: string;
  to?: string;
  backgroundColor?: string;
  color?: string;
  noShadow?: boolean;
  noBorder?: boolean;
  variant?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}


export const ButtonV2 = React.forwardRef<any, ButtonProps>(({
  className, 
  to,
  href,
  children,
  variant,
  onClick,
  ...props
}, ref) => {
    if (to) {
      return (
        <ToStyledButton
          type="button"
          to={to}
          className={className}
          {...props}
          ref={ref}
        >
          {children}
        </ToStyledButton>
      )
    }
    if (href) {
      return (
        <HrefStyledButton
          type="button"
          href={href}
          className={className}
          {...props}
          ref={ref}
        >
          {children}
        </HrefStyledButton>
      )
    }
    if (variant === 'secondary') {
      return (
        <SecondaryStyledButton
          type="button"
          className={className}
          onClick={onClick}
          {...props}
          ref={ref}
        >
          {children}
        </SecondaryStyledButton>
      )
    }
    return (
      <PrimaryStyledButton
        type="button"
        className={className}
        onClick={onClick}
        {...props}
        ref={ref}
      >
        {children}
      </PrimaryStyledButton>
    )
  }
);