var _templateObject, _templateObject2, _templateObject3;
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import colors from '#src/colors';
import { css, keyframes } from '@emotion/react';
var ssLoading = keyframes(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n from {transform: translate3d(-100%, 0, 0);}\n to {transform: translate3d(100%, 0, 0);}\n"])));
var SkeletonBasic = css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  position: relative;\n  overflow: hidden;\n  display: block;\n  background-color: ", ";\n  &:after {\n    content: '';\n    animation: ", " 2.3s infinite;\n    height: 100%;\n    left: 0;\n    position: absolute;\n    right: 0;\n    top: 0;\n    transform: translateX(-100%);\n    z-index: 1;\n    background: linear-gradient(240deg, rgba(68, 76, 96, 0.15) 0%, rgba(107, 114, 128, 0.01) 107.54%);\n  }\n"])), colors.lighestGrey, ssLoading);
export var SkeletonLine = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  ", "\n  width: ", ";\n  height: ", "px;\n  border-radius: 32px;\n"])), SkeletonBasic, function (_ref) {
  var width = _ref.width;
  return width ? "".concat(width, "px") : '64px';
}, function (_ref2) {
  var height = _ref2.height;
  return height || 14;
});