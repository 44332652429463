import { store } from './store';
import clockTicking from '../public/audio/clock-ticking.wav';
import beeping from '../public/audio/beeping.wav';
import yourTurn from '../public/audio/your-turn.wav';
import correct from '../public/audio/correct.wav';
import incorrect from '../public/audio/incorrect.mp3';
import incorrectBuzzer from '../public/audio/incorrect-buzzer.mp3';
import drawing from '../public/audio/drawing.wav';
var play = function play(sound) {
  var _userState$user;
  var audio = new Audio(sound);
  var _store$getState = store.getState(),
    userState = _store$getState.user;
  if ((_userState$user = userState.user) !== null && _userState$user !== void 0 && (_userState$user = _userState$user.preferences) !== null && _userState$user !== void 0 && _userState$user.audioMuted) {
    audio.volume = 0;
  }
  audio.play();
  return audio;
};
export default {
  play: play,
  sounds: {
    clockTicking: clockTicking,
    beeping: beeping,
    yourTurn: yourTurn,
    correct: correct,
    incorrect: incorrect,
    incorrectBuzzer: incorrectBuzzer,
    drawing: drawing
  }
};