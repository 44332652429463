import React, { useState } from 'react';
import Modal from './base/Modal';
import { ButtonV2 }  from '#src/components/base/ButtonV2';
import styled from 'styled-components';
import TextArea from '#src/components/base/TextArea';
import colors from '#src/colors';
import STATES from '#src/enums/feedbackPromptStates';
import PropTypes from 'prop-types';
import DogBeggingIcon from '#public/icons/dog-begging.svg';
import { updatePreferences } from '#src/actions/user.actions';
import { useDispatch } from 'react-redux';
import { trackEvent } from '#src/api';

const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

const ReviewModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`

const FeedbackPrompt = ({initialState}) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(true);
  const [state, setState] = useState(initialState);
  const [feedback, setFeedback] = useState('');
  const reviewUrl = 'https://www.trustpilot.com/review/teambridge.app';

  const onQuestionAnswered = (isEnjoying: boolean) => {
    if (isEnjoying) {
      setState(STATES.TRUSTPILOT);
    } else {
      setState(STATES.FEEDBACK);
    }
  };

  const onSubmitFeedback = () => {

  };

  const onReviewAnswered = (giveReview: boolean) => {
    setIsOpen(false);
    if (giveReview) {
      window.open(reviewUrl, '_blank');
    }
    dispatch(updatePreferences({ notAskForReviewAt: Date.now() }));
    trackEvent('Review Prompt Answered', { giveReview });
  }

  const getContent = () => {
    if (state === STATES.QUESTION) {
      return (
        <>
          <h3>Are you enjoying Team Bridge?</h3>
          <ButtonContainer>
            <ButtonV2 onClick={() => onQuestionAnswered(true)}>Yes</ButtonV2>
            <ButtonV2 onClick={() => onQuestionAnswered(false)}>No</ButtonV2>
          </ButtonContainer>
        </>
      );
    }
    if (state === STATES.FEEDBACK) {
      return (
        <>
          <h3>What can we do better?</h3>
          <TextArea
            placeholder="Enter your feedback here..."
            value={feedback}
            onChange={e => setFeedback(e.target.value)}
            noShadow
            rows={4}
            backgroundColor={colors.lightGrey}
            noBorder
            style={{ marginTop: '1rem' }}
          />
          <ButtonContainer>
            <ButtonV2 onClick={onSubmitFeedback}>Submit</ButtonV2>
          </ButtonContainer>
        </>
      );
    }
    if (state === STATES.TRUSTPILOT) {
      return (
        <ReviewModal>
          <h3>Glad you are enjoying TeamBridge!</h3>
          <DogBeggingIcon width={100} height={100} />
          <div style={{display: 'flex'}}>
          <p>Would you like to leave us a kind review on&nbsp;</p>
          <p style={{fontWeight: 'bold'}}>Trustpilot</p>
          <p>?</p>
          </div>
          <ButtonContainer>
            <ButtonV2 onClick={() => onReviewAnswered(false)}>No, thanks</ButtonV2>
            <ButtonV2 onClick={() => onReviewAnswered(true)}>Yes! Let's go</ButtonV2>
          </ButtonContainer>
        </ReviewModal>
      );
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => setIsOpen(false)}
    >
      {getContent()}
    </Modal>
  );
};

FeedbackPrompt.propTypes = {
  initialState: PropTypes.oneOf(Object.values(STATES)),
};

export default FeedbackPrompt;
