function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { SET_GAMESTEP, SET_GAMESTEP_SUBMITTING } from '../actions/gameStep.actions';
var initialState = {
  gameStep: null,
  data: null,
  submitting: null
};
export default (function () {
  var _state$gameStep2;
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case SET_GAMESTEP:
      {
        var _state$gameStep;
        if (!action.payload) {
          return _objectSpread(_objectSpread({}, state), {}, {
            gameStep: null,
            data: null,
            submitting: null
          });
        }
        var nextGameStep = _objectSpread({}, state.gameStep);
        var nextData = _objectSpread({}, state.data);
        var nextSubmitting = state.submitting;
        var _action$payload = action.payload,
          gameStep = _action$payload.gameStep,
          data = _action$payload.data;
        if (gameStep && state.gameStep) {
          if (gameStep._id === state.gameStep._id) {
            Object.assign(nextGameStep, gameStep);
          } else {
            nextGameStep = _objectSpread({}, gameStep);
            nextData = {};
          }
        } else if (gameStep) {
          nextGameStep = _objectSpread({}, gameStep);
        }
        if (data) {
          Object.assign(nextData, data);
        }
        if (nextGameStep.updatedAt !== state.submitting || (gameStep === null || gameStep === void 0 ? void 0 : gameStep._id) !== ((_state$gameStep = state.gameStep) === null || _state$gameStep === void 0 ? void 0 : _state$gameStep._id)) {
          nextSubmitting = null;
        }
        return _objectSpread(_objectSpread({}, state), {}, {
          gameStep: nextGameStep,
          data: nextData,
          submitting: nextSubmitting
        });
      }
    case SET_GAMESTEP_SUBMITTING:
      return _objectSpread(_objectSpread({}, state), {}, {
        submitting: (_state$gameStep2 = state.gameStep) === null || _state$gameStep2 === void 0 ? void 0 : _state$gameStep2.updatedAt
      });
    default:
      return state;
  }
});