var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12;
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import styled from 'styled-components';
import colors from '#src/colors';
export var H1 = styled.h1(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  font-size: 36px;\n  margin-bottom: .5rem;\n  color: ", ";\n"])), function (props) {
  return props.color || colors.text.primary;
});
export var H2 = styled.h2(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  font-size: 30px;\n  margin-bottom: .5rem;\n  color: ", ";\n"])), function (props) {
  return props.color || colors.text.primary;
});
export var H3 = styled.h3(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  font-size: 24px;\n  margin-bottom: .5rem;\n  color: ", ";\n"])), function (props) {
  return props.color || colors.text.primary;
});
export var H4 = styled.h4(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  font-size: 20px;\n  margin-bottom: .5rem;\n  color: ", ";\n"])), function (props) {
  return props.color || colors.text.primary;
});
export var H5 = styled.h5(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  font-size: 18px;\n  margin-bottom: .5rem;\n  color: ", ";\n"])), function (props) {
  return props.color || colors.text.primary;
});
export var H6 = styled.h6(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  font-size: 16px;\n  margin-bottom: .5rem;\n  color: ", ";\n"])), function (props) {
  return props.color || colors.text.primary;
});
export var BodySmall = styled.p(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  font-size: 14px;\n  line-height: 1.5;\n  color: ", ";\n"])), function (props) {
  return props.color || colors.text.primary;
});
export var BodyMid = styled.p(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  font-size: 16px;\n  line-height: 1.5;\n  color: ", ";\n"])), function (props) {
  return props.color || colors.darkGrey;
});
export var BodyLarge = styled.p(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  font-size: 18px;\n  line-height: 1.5;\n  color: ", ";\n"])), function (props) {
  return props.color || colors.text.primary;
});
export var Small = styled.small(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  font-size: 14px;\n  line-height: 1.5;\n  color: ", ";\n"])), function (props) {
  return props.color || colors.text.primary;
});
export var Span = styled.span(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  font-size: 16px;\n  line-height: 1.5;\n  color: ", ";\n"])), function (props) {
  return props.color || colors.text.primary;
});
export var A = styled.a(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n  font-size: 16px;\n  line-height: 1.5;\n  color: ", ";\n  text-decoration: none;\n  &:hover {\n    text-decoration: underline;\n  }\n"])), function (props) {
  return props.color || colors.text.primary;
});