export default [{
  id: 'submissionTime',
  name: 'Submission Time (seconds)',
  type: Number,
  input: 'input',
  inputType: 'number',
  "default": 160,
  min: 1,
  max: 360
}, {
  id: 'guessTime',
  name: 'Guess Time (seconds)',
  type: Number,
  input: 'input',
  inputType: 'number',
  "default": 30,
  min: 1,
  max: 360
}];