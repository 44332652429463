export default [{
  id: 'drawTime',
  name: 'Draw Time (seconds)',
  type: Number,
  input: 'input',
  inputType: 'number',
  "default": 45,
  min: 1,
  max: 360
}, {
  id: 'guessTime',
  name: 'Guess Time (seconds)',
  type: Number,
  input: 'input',
  inputType: 'number',
  "default": 45,
  min: 1,
  max: 360
}];