export default [{
  id: 'numberOfPrompts',
  name: 'Number of Prompts',
  type: Number,
  input: 'input',
  inputType: 'number',
  "default": 10,
  min: 1,
  max: 50
}, {
  id: 'time',
  name: 'Time Limit (seconds)',
  type: Number,
  input: 'input',
  inputType: 'number',
  "default": 90,
  min: 1,
  max: 256
}];