export default [{
  id: 'roundTime',
  name: 'Round Time (seconds)',
  type: Number,
  input: 'input',
  inputType: 'number',
  "default": 60,
  min: 1,
  max: 360
}, {
  id: 'turnsPerPlayer',
  name: 'Turns Per Player',
  type: Number,
  input: 'input',
  inputType: 'number',
  "default": 1,
  min: 1,
  max: 10
}];