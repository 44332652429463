/* eslint-disable object-shorthand */

import GeneralKnowledge from '../../../../public/icons/trivia/general_knowledge.svg';
import Books from '../../../../public/icons/trivia/books.svg';
import Film from '../../../../public/icons/trivia/film.svg';
import Music from '../../../../public/icons/trivia/music.svg';
import Theatre from '../../../../public/icons/trivia/theatre.svg';
import Television from '../../../../public/icons/trivia/television.svg';
import VideoGames from '../../../../public/icons/trivia/video_games.svg';
import BoardGames from '../../../../public/icons/trivia/board_game.svg';
import Science from '../../../../public/icons/trivia/science.svg';
import Computer from '../../../../public/icons/trivia/computer.svg';
import Math from '../../../../public/icons/trivia/math.svg';
import MythologyBook from '../../../../public/icons/trivia/mythology.svg';
import Sports from '../../../../public/icons/trivia/sports.svg';
import Geography from '../../../../public/icons/trivia/globe.svg';
import History from '../../../../public/icons/trivia/history.svg';
import Politics from '../../../../public/icons/trivia/politics.svg';
import Art from '../../../../public/icons/trivia/art.svg';
import Spotlight from '../../../../public/icons/trivia/spotlight.svg';
import Owl from '../../../../public/icons/trivia/owl.svg';
import Car from '../../../../public/icons/trivia/car.svg';
import Comics from '../../../../public/icons/trivia/comics.svg';
import Gadget from '../../../../public/icons/trivia/gadget.svg';
import Anime from '../../../../public/icons/trivia/anime.svg';
import Disney from '../../../../public/icons/trivia/disney.svg';
export default {
  "default": GeneralKnowledge,
  'General Knowledge': GeneralKnowledge,
  'Entertainment: Books': Books,
  'Entertainment: Film': Film,
  'Entertainment: Music': Music,
  'Entertainment: Musicals & Theatres': Theatre,
  'Entertainment: Television': Television,
  'Entertainment: Video Games': VideoGames,
  'Entertainment: Board Games': BoardGames,
  'Science & Nature': Science,
  'Science: Computers': Computer,
  'Science: Mathematics': Math,
  Mythology: MythologyBook,
  Sports: Sports,
  Geography: Geography,
  History: History,
  Politics: Politics,
  Art: Art,
  Celebrities: Spotlight,
  Animals: Owl,
  Vehicles: Car,
  'Entertainment: Comics': Comics,
  'Science: Gadgets': Gadget,
  'Entertainment: Japanese Anime & Manga': Anime,
  'Entertainment: Cartoon & Animations': Disney
};