import { combineReducers } from 'redux';
import userReducer from './user.reducer';
import gameStepReducer from './gameStep.reducer';
import lobbyReducer from './lobby.reducer';
import teamReducer from './team.reducer';
export default combineReducers({
  user: userReducer,
  gameStep: gameStepReducer,
  lobby: lobbyReducer,
  team: teamReducer
});